import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  {
    path: 'convo',
    loadChildren: () => import('./pages/convo/convo.module').then( m => m.ConvoPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'einstellungen',
    loadChildren: () => import('./pages/einstellungen/einstellungen.module').then( m => m.EinstellungenPageModule)
  },
  {
    path: 'lernen',
    loadChildren: () => import('./pages/lernen/lernen.module').then( m => m.LernenPageModule)
  },
  {
    path: 'nachhilfe',
    loadChildren: () => import('./pages/nachhilfe/nachhilfe.module').then( m => m.NachhilfePageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'meinedateien',
    loadChildren: () => import('./pages/meinedateien/meinedateien.module').then( m => m.MeinedateienPageModule)
  },
  {
    path: 'meinefavoriten',
    loadChildren: () => import('./pages/meinefavoriten/meinefavoriten.module').then( m => m.MeinefavoritenPageModule)
  },
  {
    path: 'meinenews',
    loadChildren: () => import('./pages/meinenews/meinenews.module').then( m => m.MeinenewsPageModule)
  },
  {
    path: 'news-anlegen',
    loadChildren: () => import('./pages/news-anlegen/news-anlegen.module').then( m => m.NewsAnlegenPageModule)
  },
  {
    path: 'convo-pr',
    loadChildren: () => import('./pages/convo-pr/convo-pr.module').then( m => m.ConvoPRPageModule)
  },
  {
    path: 'chat-anlegen',
    loadChildren: () => import('./pages/chat-anlegen/chat-anlegen.module').then( m => m.ChatAnlegenPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
